<template>
  <carousel
    :perPage="3"
    :scrollPerPage="true"
    :navigationEnabled="true"
    :autoplay="false"
    :paginationEnabled="false"
    class="grid justify-items-center items-center mx-5 sm:mx-0"
  >
    <slide v-for="data in sliderNews" :key="data.judul" class="w-full">
      <div
        class="relative max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer m-5"
      >
        <div>
          <img
            :src="data.link_foto"
            :alt="data.judul"
            class="object-cover h-40 w-full"
          />
        </div>
        <div class="py-4 px-4 bg-white">
          <span class="my-2 text-sm text-gray-600">{{ data.tanggal }}</span>
          <span class="text-md font-semibold text-gray-600 truncate">
            {{ data.judul }}
          </span>
          <span class="mt-4 text-sm text-gray-600">Read More...</span>
        </div>
        <a class="absolute inset-0" @click="details(data)" />
      </div>
    </slide>
  </carousel>
</template>

<script>
import Vue from "vue";
import { Carousel, Slide } from "vue-carousel";
import { Slugify } from "../utils";

export default {
  name: "SliderNews",
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    sliderNews: [],
    apiTripwe: process.env.VUE_APP_TRIPWE_PUBLIC_API,
    apiSaebo: process.env.VUE_APP_SAEBO_PUBLIC_API,
  }),
  mounted() {
    // window.scroll(0, 0);
  },
  methods: {
    async getNews() {
        const response = await this.$http.get(`${this.$apiTripweWeb}/news/list`);
        this.sliderNews = response.data.data.item ?? [];
    },
    convertSlug(str) {
      return Slugify(str);
    },
    details(news) {
      this.$router.push({
        name: "DetailNews",
        params: { slug: Slugify(news.judul), news },
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  created() {
    this.getNews();
  },
};
</script>
