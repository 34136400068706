<template>
  <div class="detail-news" :key="detailsNews.id">
    <div class="max-w-screen-lg lg:max-w-screen-2xl xl:max-w-screen-xl mx-auto px-3 md:px-8 mb-8">
      <div class="rounded-xl md:rounded-30 border border-rounded px-3 md:px-16 py-4 md:py-6 w-full">
        <h1 class="md:font-extrabold font-semibold md:text-38 text-2xl leading-7 md:leading-10 text-black">
          {{ detailsNews.judul }}
        </h1>
        <div class="flex justify-between items-center mt-1">
          <span class="font-normal text-xs md:text-lg text-rounded">{{ detailsNews.wilayah }}, {{ detailsNews.tanggal
          }}</span>
        </div>
        <div
          class="w-full h-64 md:h-562 group overflow-hidden shadow-lg hover:shadow-2xl hover:translate-y-3 rounded md:rounded-lg mt-5 md:mt-8">
          <img class="object-cover hover:scale-125 w-full h-full group-hover:translate-y-3" :src="detailsNews.link_foto"
            :alt="detailsNews.judul" :title="detailsNews.judul" />
        </div>
        <div class="w-full py-3 md:py-6">
          <span class="font-normal text-black2 text-base md:text-lg leading-5 md:leading-7" v-html="detailsNews.isi">
          </span>
        </div>
        <SliderNews />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import SliderNews from "@/components/SliderNews";

export default {
  name: "DetailNews",
  components: {
    Breadcrumb,
    SliderNews,
  },
  data: () => ({
    detailsNews: {},
    news: [],
  }),
  computed: {
    availableNews() {
      return this.news.filter(val => val.id != this.detailsNews.id);
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.news === undefined) {
      next({ name: 'NewsIndex' });
    } else {
      next();
    }
  },
  async created() {
    this.$store.commit('setOverlayLoading', true);

    this.detailsNews = this.$route.params.news;

    this.$store.commit('setBreadcrumbItems', [
      { text: 'Home', routeName: 'Home' },
      { text: 'News', routeName: 'NewsIndex' },
      { text: this.detailsNews.judul, routeName: 'NewsDetail' }
    ]);

    await this.getNews();

    this.$store.commit('setOverlayLoading', false);
  },
  methods: {
    async getNews() {
      this.$store.commit('setOverlayLoading', true);

      try {
        const response = await this.$http.get(`${this.$apiTripweWeb}/news/list`);

        this.news = response.data.data.item ?? [];
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit('setOverlayLoading', false);
    },
    selectNews(nws) {
      this.detailsNews = nws;

      this.$store.commit('setBreadcrumbItems', [
        { text: 'Home', routeName: 'Home' },
        { text: 'News', routeName: 'NewsIndex' },
        { text: nws.judul, routeName: 'NewsDetail' }
      ]);
    },
  },
};
</script>
