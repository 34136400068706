<template>
  <div class="breadcrumb">
    <div class="w-full py-2 px-3 sm:px-7">
       <div class="text-primary font-bold text-x">
          Home <span class="text-black font-medium">|</span> {{ page }} <span class="text-black font-medium">| {{ subPage }}</span>
       </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: ['page', 'subPage'],
}
</script>